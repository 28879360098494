import Color from "@/types/Color";
import FullMove from "@/classes/work/FullMove";
import Strike from "@/classes/work/Strike";
import Checker from "@/classes/work/Checker";
import Vector from "@/types/Vector";
import HalfMove from "@/classes/work/HalfMove";
import {Position} from "@/classes/work/Position";

export type PositionData = Array<Checker | undefined>

export default class Board extends Position {

    private readonly vectors: Vector[][]

    public strikeMoveList?: FullMove[]


    constructor(public size: number, data?: PositionData, public nextMove?: Color,
                public deepCalc?: boolean) {
        super(size, data, nextMove)
        this.vectors = new Array(size * size)
        let ds = [size + 1, size - 1, -size - 1, -size + 1]
        this.vectors = new Array<Array<Vector>>(size * size)

        for (let cell = 0; cell < size * size; cell++) {
            if (!this.isOnBoard(cell)) continue
            this.vectors[cell] = []
            for (let d = 0; d < 4; d++) {
                let nextCell = cell
                if (this.isOnBoard(nextCell + ds[d])) this.vectors[cell][d] = {points: [cell], step: ds[d]}
                while (this.isOnBoard(nextCell += ds[d]))
                    this.vectors[cell][d].points.push(nextCell)
            }
        }

    }

    strikeMoveDo(move: FullMove) {
        [this.data[move.from], this.data[move.to]] = [this.data[move.to], this.data[move.from]];
        if (move.kingMove) {
            let ch = this.data[move.to]!
            ch.isKing = true
            this.kings[ch.color]++

        }
        if (move.takeList)
            for (let checker of move.takeList) {
                this.data[checker.pos] = undefined
                this.checkers?.get(checker.color)?.delete(checker)
                if (checker.isKing) this.kings[checker.color]--
            }
    }

    strikeMoveUndo(move: FullMove) {
        [this.data[move.from], this.data[move.to]] = [this.data[move.to], this.data[move.from]];
        if (move.kingMove) {
            let ch = this.data[move.to]!
            ch.isKing = false
            this.kings[ch.color]--
        }
        if (move.takeList)
            for (let checker of move.takeList) {
                this.data[checker.pos] = checker
                this.checkers?.get(checker.color)?.add(checker)
            }
    }

    public makeMove(move: FullMove) {
        let ch = this.data[move.from]
        if (!ch) return
        ch.pos = move.to
        if (!ch.isKing && this.isKingRow(ch)) {
            ch.isKing = true
            move.kingMove = true
        }
        [this.data[move.from], this.data[move.to]] = [this.data[move.to], this.data[move.from]]
        let takePos = (move as Strike).takePos
        if (takePos !== undefined) this.data[takePos]!.struck = !this.data[takePos]!.struck
    }
    public makeUnMove(move: FullMove) {
        let ch = this.data[move.to]
        if (!ch) return
        ch.pos = move.from
        if (move.kingMove) {
            ch.isKing = false
        }
        [this.data[move.from], this.data[move.to]] = [this.data[move.to], this.data[move.from]]
        let takePos = (move as Strike).takePos
        if (takePos !== undefined) this.data[takePos]!.struck = !this.data[takePos]!.struck
    }

    getMoveVectors(pos: number, move?: Color) {
        // move: Color of move
        // or Cell from hit track for ban hit back by prev strike vector direction
        let v = this.vectors[pos]
        if (move !== undefined) return v.filter((it, index) => {
                return move === Color.White ? index < 2 : index >= 2
            }
        )
        return v
    }

    getStrikeVectors(pos: number, banSteps: Array<number | undefined>) {
        return this.vectors[pos].filter((v) => !banSteps.includes(v.step))
    }

    private isKingRow(ch: Checker) {
        return this.isKingRowPos(ch.color, ch.pos)
    }

    private isKingRowPos(color: Color, pos: number) {
        return (color === Color.White) ? pos > this.size * (this.size - 1) : pos < this.size
    }

    private moveList(ch: Checker) {
        let res: HalfMove[] = []
        let vList = this.getMoveVectors(ch.pos, !ch.isKing ? ch.color : undefined)
        for (let v of vList) {
            if (!v) continue
            for (let i = 1; i < (ch.isKing ? v.points.length : 2); i++) {
                if (!this.data[v.points[i]]) {
                    let move = new HalfMove(v, i)
                    move.kingMove = !ch.isKing && this.isKingRowPos(ch.color, move.to)
                    res.push(move)
                    this.strikeMoveList?.push(new FullMove(move.from, move.to, undefined,
                        move.kingMove))
                } else break
            }

        }
        return res
    }

    private directionalStrike(v: Vector) {
        let ch = this.data[v.points[0]]
        if (!ch || v.points.length < 3) return
        // search for a hit (taking) in a vector in range indices [1, size -2]
        let restSteps = ch.isKing ? v.points.length - 2 : 1
        let i = 1
        while (restSteps) {
            let chV = this.data[v.points[i]]
            if (!chV) {
                i++
                restSteps--
                continue
            }
            if (chV.color !== ch.color && !chV.struck) {
                if (!this.data[v.points[i + 1]])
                    return new Strike(v, i + 1, i, restSteps)
                else return
            }
            return
        }
    }

    private strikeList(ch: Checker, banSteps: Array<number | undefined>,
                       moveListItem: { move?: FullMove } = {move: undefined}): Strike[] {
        let strikeToMove = (strike: Strike, moveListItem: { move?: FullMove }, kingMove?: boolean) => {
            if (!moveListItem.move) {
                moveListItem.move = new FullMove(strike.from, strike.to, [this.data[strike.takePos]!], kingMove)
            } else {
                moveListItem.move.to = strike.to
                moveListItem.move.takeList?.push(this.data[strike.takePos]!)
                if (kingMove) moveListItem.move.kingMove = kingMove
            }
        }
        let res: Strike[] = []
        let vectors = this.getStrikeVectors(ch.pos, banSteps)
        for (let v of vectors) {
            if (!v) continue
            let strike = this.directionalStrike(v)
            let nextStrike: Strike | undefined
            if (strike) {
                let iTo = strike.iTo
                let firstNextStrike = true
                let continued = false
                for (let pos of strike.restSteps) {
                    if (this.data[pos]) break
                    nextStrike = new Strike(strike.v, iTo, strike.takeI, 0)
                    this.makeMove(nextStrike)
                    strikeToMove(nextStrike, moveListItem, nextStrike.kingMove)
                    // if not first after point of take in v:Vector - ban v strike
                    // for ban duplicate strike
                    // deepCalc ban this option
                    let strikeList = this.strikeList(ch,
                        !this.deepCalc || firstNextStrike ? [-strike.v.step] :
                            [strike.v.step, -strike.v.step], moveListItem)
                    firstNextStrike = false
                    this.makeUnMove(nextStrike)
                    moveListItem.move?.takeList?.pop()
                    if (strikeList.length) {
                        nextStrike.strikeList = strikeList
                        res.push(nextStrike)
                        continued = true
                    }
                    iTo++
                }
                if (!continued) {
                    res.push(strike)
                    strikeToMove(strike, moveListItem, strike.kingMove)
                    if (moveListItem.move) {
                        for (let pos of strike.restSteps) {
                            if (this.data[pos]) break
                            moveListItem.move.to = pos
                            this.strikeMoveList?.push(moveListItem.move.copy())
                        }
                    }
                    moveListItem.move?.takeList?.pop()
                }
            }
        }
        return res
    }

    protected isOnBoard(pos: number) {
        return (pos % 2 === ((pos / this.size) >> 0) % 2) && pos >= 0 && pos < this.size * this.size
    }


    public getMoveList(color: Color) {
        // TODO: remove this.checkers
        this.nextMove = color
        this.strikeMoveList = []
        let checkers = this.checkers?.get(this.nextMove)
        if (!checkers) return
        let res = []
        for (let ch of checkers) {
            let list = this.strikeList(ch, [])
            if (list.length) {
                res.push(list)
            }
        }
        if (res.length) {
            return res
        }
        for (let ch of checkers) {
            let list = this.moveList(ch)
            if (list.length) {
                res.push(list)
            }
        }
        if (res.length) return res
    }
}

