import FrontBoard from "@/classes/FrontBoard";
import Board from "@/classes/work/Board";

export default abstract class BoardElement {
    protected constructor(public board: Board, public pos: number) {
    }

    get x() {
        return this.pos % this.board.size
    }

    get y() {
        return (this.pos / this.board.size) >> 0
    }

    set x(x: number) {
        this.pos = x + this.y * this.board.size
    }

    set y(y: number) {
        this.pos = y * this.board.size + this.x
    }
}
