import {Checkers} from "@/types/Checkers";
import Color from "@/types/Color";
import color from "@/types/Color";
import Checker from "@/classes/work/Checker";
import {PositionData} from "@/classes/work/Board";

export class Position {
    public data: PositionData
    checkers: Checkers = new Map<Color, Set<Checker>>()

    kings: number[] = [0, 0]


    getKingCnt(c: Color) {
        return this.kings[c]
    }

    getSimplesCnt(c: Color) {
        return this.checkers.get(c)?.size ?? 0 - this.kings[c]
    }

    getCnt(c: Color) {
        return this.checkers.get(c)?.size ?? 0
    }

    getTotalCnt() {
        return (this.checkers.get(Color.Black)?.size ?? 0) + (this.checkers.get(Color.White)?.size ?? 0)
    }


    init() {
        for (let ch of this.data) {
            if (ch?.isKing) this.kings[ch.color]++
        }
        let blacks: Checker[] = []
        let whites: Checker[] = []
        let list = []
        list[Color.Black] = blacks
        list[Color.White] = whites
        for (let ch of this.data)
            if (ch) list[ch.color].push(ch)
        this.checkers.set(color.Black, new Set(blacks))
        this.checkers.set(color.White, new Set(whites))
    }

    constructor(public size: number, data?: PositionData,
                public nextMove?: Color, copy?: boolean) {
        if (data) {
            this.data = [...data]
        } else this.data = []
        if (!copy) this.init()
    }

    copyPosition(): Position {
        let pos = new Position(this.size, this.data, this.nextMove, true)
        pos.kings = [...this.kings]
        pos.checkers.set(Color.Black, new Set<Checker>(this.checkers.get(Color.Black)))
        pos.checkers.set(Color.White, new Set<Checker>(this.checkers.get(Color.White)))
        return pos
    }

    isEqual(pos: Position) {
        if (this.nextMove !== pos.nextMove) return false
        if (this.checkers.size !== pos.checkers.size) return false
        for (let ch of this.data) if (ch && pos.data[ch?.pos] !== ch) return false
        return true
    }

    isPowerEqual(pos: Position) {
        return this.getCnt(Color.White) === pos.getCnt(Color.White) &&
            this.getCnt(Color.Black) === pos.getCnt(Color.Black) &&
            this.kings[Color.Black] === pos.kings[Color.Black] &&
            this.kings[Color.White] === pos.kings[Color.White]
    }
}