import FullMove from "@/classes/work/FullMove";
import Vector from "@/types/Vector";

export default class HalfMove implements FullMove {

    kingMove?: boolean

    constructor(public v: Vector, public iTo: number) {
    }

    get from() {
        return this.v.points[0]
    }

    get to() {
        return this.v.points[this.iTo]
    }

    copy() {
        return new HalfMove(this.v, this.iTo)
    }

}
