import FullMove from "@/classes/work/FullMove";
import Color from "@/types/Color";
import color from "@/types/Color";
import {Position} from "@/classes/work/Position";
import {th} from "vuetify/locale";

export type PickByType<T, Value> = {
    [P in keyof T as T[P] extends Value | undefined ? P:never]: T[P]
}
type NumKey = keyof  PickByType<Game, number>
const checkList: NumKey[] = ['kingsOnlyMoveStartAt', "kingsOnlyMoveStartAt", 'triangleStartAt',
    'powerEqualStartAt', 'mainRoadStartAt']
export class Game {
    posHistory: Position[]
    moveHistory: FullMove[] = []
    kingsStartAt?: number
    kingsOnlyMoveStartAt?: number
    triangleStartAt?: number
    powerEqualStartAt?: number
    mainRoadStartAt?: number

    getChOfMove(i: number) {
        return this.posHistory[i + 1].data[this.moveHistory[i].to]
    }

    constructor(pos: Position) {
        // init position hasn't move
        this.posHistory = [pos]
    }

    push(newPos: Position, move: FullMove): void |
        'draw 1' | 'draw 2' | 'draw 3' | 'draw 4' | 'draw 5' {
        let posIndex = this.posHistory.length - 1
        let newPosIndex = posIndex + 1
        let pos = this.posHistory[posIndex]
        this.posHistory.push(newPos)
        this.moveHistory.push(move)
        if (pos.kings[Color.White] && pos.kings[Color.Black]) {
            // first position where both set kings
            if (this.kingsStartAt === undefined || this.kingsStartAt > posIndex)
                this.kingsStartAt = posIndex
            // 1) если в течение 15 ходов игроки делали ходы только дамками, не передвигая
            // простых шашек и не производя взятия.
            if (this.getChOfMove(posIndex)?.isKing) {
                if (this.kingsOnlyMoveStartAt === undefined || this.kingsOnlyMoveStartAt > posIndex) {
                    this.kingsOnlyMoveStartAt = posIndex
                } else {
                    if (newPosIndex - this.kingsOnlyMoveStartAt >= 15) return "draw 1"
                }
            } else this.kingsOnlyMoveStartAt = undefined
            // 2) если три раза повторяется одна и та же позиция
            let i = posIndex
            let repeats = 0
            while (i >= 0 && this.posHistory[i].isPowerEqual(newPos)) {
                if (this.posHistory[i].isEqual(newPos)) repeats++
                if (repeats === 3) return 'draw 2'
                i--
            }
            // 3) если участник, имеющий три дамки (и более) против одной дамки противника,
            // за 15 ходов не возьмёт дамку противника
            if ((pos.kings[Color.White] === 1 && pos.getCnt(Color.White) === 1 &&
                    pos.kings[Color.Black] >= 3) ||
                (pos.kings[Color.Black] === 1 && pos.getCnt(Color.Black) === 1 &&
                    pos.kings[Color.White] >= 3)) {
                if (this.triangleStartAt === undefined || this.triangleStartAt > posIndex) {
                    this.triangleStartAt = posIndex
                }
                if (newPosIndex - this.triangleStartAt >= 15) return 'draw 3'

            } else this.triangleStartAt = undefined

            // 4) если в позиции, в которой оба соперника имеют дамки, не изменилось соотношение сил
            // (то есть не было взятия, и ни одна простая шашка не стала дамкой) на протяжении:
            // в 2- и 3-фигурных окончаниях — 5 ходов,
            // в 4- и 5-фигурных окончаниях — 30 ходов,
            // в 6- и 7-фигурных окончаниях — 60 ходов;
            if (pos.isPowerEqual(newPos)) {
                if (this.powerEqualStartAt === undefined || this.powerEqualStartAt > posIndex) {
                    this.powerEqualStartAt = posIndex
                }
                let total = newPos.getTotalCnt()
                if (total < 4 && newPosIndex - this.powerEqualStartAt >= 5) return 'draw 4'
                if (total < 6 && newPosIndex - this.powerEqualStartAt >= 30) return 'draw 4'
                if (total < 8 && newPosIndex - this.powerEqualStartAt >= 60) return 'draw 4'
            } else this.powerEqualStartAt = undefined
            // если участник, имея в окончании партии три дамки, две дамки и простую, дамку и две простые,
            // ""три простые против одинокой дамки"", находящейся на большой дороге,
            // своим 5-м ходом не сможет добиться выигранной позиции;
            if ((pos.kings[Color.White] === 1 &&
                    ([...pos.checkers.get(Color.White)!][0].pos % (pos.size + 1)) === 0
                    && pos.kings[color.Black] && pos.getCnt(color.Black) < 4) ||
                (pos.kings[Color.Black] === 1 &&
                    ([...pos.checkers.get(Color.Black)!][0].pos % (pos.size + 1)) === 0
                    && pos.kings[color.White] && pos.getCnt(color.White) < 4)) {
                if (this.mainRoadStartAt === undefined || this.mainRoadStartAt < posIndex) {
                    this.mainRoadStartAt = posIndex
                }
                if (newPosIndex - this.mainRoadStartAt == 5) return 'draw 5'

            } else this.mainRoadStartAt = undefined
        }
    }

    pop() {
        if (this.moveHistory.length) {
            this.moveHistory.pop()
            this.posHistory.pop()
            let posIndex = this.posHistory.length
            for (let key of checkList)
                if (this[key]===posIndex) this[key] = undefined
        }
    }


}