import FrontBoard from "@/classes/FrontBoard";
import FrontCell from "@/classes/FrontCell";
import Color from "@/types/Color";
import Strike from "@/classes/work/Strike";
import HalfMove from "@/classes/work/HalfMove";
import {Checkers} from "@/types/Checkers";
import Checker from "@/classes/work/Checker";
import FullMove from "@/classes/work/FullMove";

function isEqualSet(s1: Array<Checker> | undefined, s2: Set<Checker>) {
    if (!s1 && !s2.size) return true
    if (!s1) return false
    if (s1.length !== s2.size) return false
    for (let ch of s1) if (!s2.has(ch)) return false
    return true
}

export default class FrontMove {
    public moveList: Array<Strike[] | HalfMove[]> | undefined
    public checker?: Checker
    public inProgress = false
    private moveTrack: FullMove[] = []


    constructor(public board: FrontBoard, public color: Color) {
    }

    public initBoard() {
        let checkers: Checkers = new Map
        let bCh = this.board.cells.filter(it => it.checker?.color === Color.Black)
            .map(cell => new Checker(cell.checker!.pos, cell.checker!.isKing, Color.Black, this.board))
        checkers.set(Color.Black, new Set<Checker>(bCh))
        let wCh = this.board.cells.filter(it => it.checker?.color === Color.White)
            .map(cell => new Checker(cell.checker!.pos, cell.checker!.isKing, Color.White, this.board))
        checkers.set(Color.White, new Set<Checker>(wCh))
        // this.workBoard = new WorkBoard(this.board.size, this.board.data, this.color, true)
    }

    public execute(cell: FrontCell) {
        if (cell.selected) {
            let moveVariants = this.board.cells[this.checker!.pos].moveVariants
            let move
            if (!(moveVariants[0] instanceof Strike)) {
                for (move of moveVariants) if (move.to === cell.pos) break
            } else {
                for (let strike of (moveVariants as Strike[])) {
                    if (strike.strikeList) {
                        if (strike.to === cell.pos) {
                            move = strike
                            break
                        }
                    } else {
                        let iTo = strike.iTo
                        for (let pos of strike.restSteps) {
                            if (pos === cell.pos) {
                                move = new Strike(strike.v, iTo, strike.takeI, strike.restStepsCnt)
                                break
                            }
                            iTo++
                        }
                    }
                    if (move) break
                }
            }
            if (!move) return
            this.checker = this.board.data[move.from]
            this.board.makeMove(move)
            this.moveTrack?.push(move)
            this.unselect(cell)
            if (cell.strikeList) {
                this.selectVariants(cell.strikeList)
                this.inProgress = true
                return
            }
            let from = this.moveTrack[0].from
            let to = this.moveTrack[this.moveTrack.length-1].to
            ;[this.board.data[from], this.board.data[to]] = [this.board.data[to], this.board.data[from]];
            this.inProgress = false
            let takeSet = new Set<Checker>()
            this.moveTrack.forEach(move => {
                if ((move as Strike).takePos !== undefined) {
                    takeSet.add(this.board.cells[(move as Strike).takePos].checker!);
                }
            })

            let list = this.board.strikeMoveList?.filter(
                move => move.from === this.moveTrack[0].from &&
                move.to === this.moveTrack[this.moveTrack.length - 1].to &&
                isEqualSet(move.takeList, takeSet)
            )
            if (list?.length) {
                this.board.strikeMoveDo(list[0])
                this.board.game?.push(this.board.copyPosition(), list[0])
                console.log(this.board.game)
            }
            this.moveTrack = []
            this.color = this.color ^ 1
            return
        } else if (cell.checker && !this.inProgress) {
            this.moveList = this.board.getMoveList(this.color)
            console.log(this.board.strikeMoveList?.map(x => [x.from, x.to,
                ...(x.takeList ? x.takeList.map(ch => ch.pos) : [undefined]), x.kingMove
            ]), this.moveList)
            this.unselect()
            this.checker = cell.checker
            let moveVariants = this.moveList?.filter(move => move[0].from === cell.pos)[0]
            if (moveVariants?.length) this.selectVariants(moveVariants)
            return
        }
    }

    private selectVariants = (moveVariants?: HalfMove[]) => {
        if (moveVariants?.length) {
            this.board.cells[moveVariants[0].from].moveVariants = moveVariants
            for (let move of moveVariants) {
                this.board.cells[move.to].selected = true
                this.board.cells[move.to].strikeList = (move as Strike).strikeList
                if (move instanceof Strike && !move.strikeList?.length)
                    for (let pos of move.restSteps) {
                        if (this.board.data[pos]) break
                        this.board.cells[pos].selected = true
                    }
            }
        }
    }

    private unselect(cell?: FrontCell) {
        this.board.cells.map(c => {
            c.selected = false
            if (c !== cell) c.strikeList = undefined
        })
    }
}
