import Color from "@/types/Color";
import FrontBoard from "@/classes/FrontBoard";
import Checker from "@/classes/work/Checker";

export default class FrontChecker extends Checker {

    constructor(public board: FrontBoard, public pos: number, public color: Color, public isKing: boolean = false) {
        super(pos, isKing, color, board)
    }
}
