import HalfMove from "@/classes/work/HalfMove";
import Vector from "@/types/Vector";
import FullMove from "@/classes/work/FullMove";


export default class Strike extends HalfMove {
    strikeList?: Strike[]

    constructor(v: Vector,
                iTo: number,
                public takeI: number,
                public restStepsCnt: number) {
        super(v, iTo)
    }

    copy() {
        return new Strike(this.v, this.iTo, this.takeI, this.restStepsCnt)
    }

    get takePos() {
        return this.v.points[this.takeI]
    }

    restSteps = {
        [Symbol.iterator]: (): Iterator<number> => {
            let n = this.restStepsCnt
            let i = this.iTo
            return {
                next: () => {
                    if (n !== undefined && n--) {
                        return {done: false, value: (this as Strike).v.points[i++]}
                    } else return {done: true, value: undefined}
                }
            }
        }
    }
}
