import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import routes from "@/router/routes.json";
import Route from "@/types/Route";

function loadRoutes(routes: Array<Route>): Array<RouteRecordRaw> {
  return routes.map(r => {return {
    path: r.path,
    name: r.name,
    component: () => import(`@/views/${r.component}`),
    children: r.children ? loadRoutes(r.children) : []
  }})
}

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: loadRoutes(routes)
})

export default router
