import FrontCell from "@/classes/FrontCell";
import FrontChecker from "@/classes/FrontChecker";
import CheckerType from "@/types/CheckerType";
import Board from "@/classes/work/Board";
import FullMove from "@/classes/work/FullMove";
import {Game} from "@/classes/work/Game";

export default class FrontBoard extends Board{
    game?: Game
    public reverse: boolean = false;
    public R: number = 2;
    public a: number = 100/(this.size+2/this.R);
    public a0 = this.a/this.R;
    public cells: Array<FrontCell> = new Array<FrontCell>(this.size**2);
    public editor?: CheckerType;

    constructor (public size: number = 8) {
        super(size)
        for(let i = 0; i < this.size**2; i++) {
            this.cells[i] = new FrontCell(this, i)
        }
    }

    public generateCheckers() {
        for(let i = 0; i < this.size*3; i++) {
            if (this.isOnBoard(i)) {
                let whiteChecker = new FrontChecker(this, i, 0)
                let blackChecker = new FrontChecker(this, this.size**2-i-1, 1)
                this.data[whiteChecker.pos] = whiteChecker
                this.data[blackChecker.pos] = blackChecker
            }
        }
        this.init()
        this.game = new Game(this.copyPosition())
    }

    public clearCheckers() {
        this.cells.map(c => c.checker = undefined);
        this.checkers?.clear();
        this.data = [];
        this.init()
    }

}
