import Color from "@/types/Color";
import BoardElement from "@/classes/BoardElement";
import FrontBoard from "@/classes/FrontBoard";
import Strike from "@/classes/work/Strike";
import HalfMove from "@/classes/work/HalfMove";
import Checker from "@/classes/work/Checker";

export default class FrontCell extends BoardElement {
    public color: Color
    public selected: boolean = false
    public strikeList?: Strike[]
    public moveVariants: HalfMove[] = []

    constructor(public board: FrontBoard, public pos: number) {
        super(board, pos)
        this.color = Number(this.isBlack())
    }

    get checker () {
        return this.board.data[this.pos]
    }

    set checker (ch: Checker | undefined) {
        this.board.data[this.pos] = ch
    }

    public isBlack(): boolean {
        return !((this.x + this.y) % 2)
    }
}
