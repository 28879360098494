import Color from "@/types/Color";
import BoardElement from "@/classes/BoardElement";
import Board from "@/classes/work/Board";

export default class Checker extends BoardElement {
    public struck: boolean = false

    constructor(
        public pos: number,
        public isKing: boolean,
        public color: Color,
        public board: Board
    ) {
        super(board, pos)
    }


    reverseColor() {
        this.color = this.color ^ 1
    }
}
