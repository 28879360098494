import FrontBoard from "@/classes/FrontBoard";
import BoardSign from "@/types/BoardSign";

export default function CreateBoardSigns(board: FrontBoard): Array<BoardSign> {
    let signs: Array<BoardSign> = [];
    for (let i = 0; i < board.size * 2; i++) {
        let num = board.reverse ? board.size - i % board.size - 1 : i % board.size;
        signs.push({
            x: board.a * (i % board.size) + board.a0,
            y: i < board.size ? 0 : 100 - board.a0,
            w: board.a,
            h: board.a0,
            content: String.fromCharCode('A'.charCodeAt(0) + num)
        })
        signs.push({
            x: i < board.size ? 0 : 100 - board.a0,
            y: board.a * (i % board.size) + board.a0,
            w: board.a0,
            h: board.a,
            content: (board.size - num).toString()
        })
    }
    return signs;
}
