import Ch from "@/classes/work/Checker";

export default class FullMove {
    constructor(
        public from: number,
        public to: number,
        public takeList?: Ch[],
        public kingMove?: boolean) {
    }


    copy () {
        return new FullMove(this.from, this.to, this.takeList ? [...this.takeList] : undefined, this.kingMove)
    }
}
