import Color from "@/types/Color";

type CheckerType = 'white'
    | 'black'
    | 'whiteKing'
    | 'blackKing'

export default CheckerType;

export const checkerTypeMap = new Map<CheckerType | undefined, { color: Color, isKing: boolean }>([
    ["white", {color: Color.White, isKing: false}],
    ["black", {color: Color.Black, isKing: false}],
    ["whiteKing", {color: Color.White, isKing: true}],
    ["blackKing", {color: Color.Black, isKing: true}],
])
